.review-slider-container {
    cursor: grab;
    padding: 0px 120px;

    .review-img {
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 10%;
        height: 320px;
        width: 320px;
    }

    .review-text {
        text-align: left;
        font-size: 24px;
        line-height: 34px;
        font-weight: 500;
    }

    .customer-info {
        text-align: left;
        border-left: 2px solid rgb(221 221 221);

        .name {
            font-weight: 500;
            font-size: 20px;
        }

        .designation {
            color: rgb(184, 184, 184);
        }
    }
}

@media only screen and (max-width: 1200px) {
    .review-slider-container {
        padding: 0px !important;
    }
}