.app {

    .side-padding {
        padding: 10px 130px;
    }

    /* Common button styles */
    .button {
        float: left;
        display: block;
        padding: 8px 20px 10px 20px;
        font-weight: 500;
        border: none;
        background: none;
        vertical-align: middle;
        position: relative;
        z-index: 1;
        -webkit-backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        cursor: pointer;
    }

    .button:focus {
        outline: none;
    }

    .button>span {
        vertical-align: middle;
    }

    /* Winona */
    .button--winona {
        overflow: hidden;
        padding: 0;
        -webkit-transition: border-color 0.3s, background-color 0.3s;
        transition: border-color 0.3s, background-color 0.3s;
        -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
        transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
        border-radius: 14px;
        border: 2px solid;
    }

    .button--winona::after {
        content: attr(data-text);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        color: white;
        -webkit-transform: translate3d(0, 25%, 0);
        transform: translate3d(0, 25%, 0);
    }

    .button--winona>span {
        display: block;
    }

    .button--winona::after,
    .button--winona>span {
        padding: 8px 20px 10px 20px;
        -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
        transition: transform 0.3s, opacity 0.3s;
        -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
        transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    }

    .button--winona:hover {
        border-color: black;
        background-color: black;
    }

    .button--winona:hover::after {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .button--winona:hover>span {
        opacity: 0;
        -webkit-transform: translate3d(0, -25%, 0);
        transform: translate3d(0, -25%, 0);
    }

    /* ------ Green button ------*/
    .button-green {
        background-color: rgb(177 252 3);
        border-color: rgb(177 252 3) !important;
    }
    
    .button-green:hover {
        background-color: rgb(177 252 3) !important;
    }
    
    .button-green::after {
        color: black !important;
    }

    /* ------ Black button ------*/
    .button-black {
        background-color: black;
        border-color: black !important;
        color: white !important;
    }
    
    .button-black:hover {
        background-color: black !important;
    }
    
    .button-black::after {
        color: white !important;
    }
}

@media only screen and (min-width: 1600px) {
    .app {
        .side-padding {
            padding: 10px 300px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .app {
        .side-padding {
            padding: 10px 20px;
        }
    }
}